.daycy.date-picker,
.daycy.time-picker {
    max-width: 19.5rem;
}

.daycy.date-range-picker,
.daycy.time-range-picker,
.daycy.week-picker {
    display: flex;
    align-items: center;
    background-color: #FFF;
    border-radius: 0.28571429rem;
    min-width: 16.5rem;
    max-width: 19.5rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.form .daycy.date-picker,
.daycy.date-picker.fluid,
.ui.form .daycy.time-picker,
.daycy.time-picker.fluid,
.ui.form .daycy.week-picker,
.daycy.week-picker.fluid,
.ui.form .daycy.date-range-picker,
.daycy.date-range-picker.fluid,
.ui.form .daycy.time-range-picker,
.daycy.time-range-picker.fluid {
    max-width: unset;
}

.daycy.date-range-picker.focus,
.daycy.time-range-picker.focus,
.daycy.week-picker.focus {
    border-color: #85b7d9;
}

.daycy.date-range-picker > .ui.input,
.daycy.time-range-picker > .ui.input,
.daycy.week-picker > .ui.input {
    flex: 1 1 auto;
    width: 0 !important;
}

.daycy.date-range-picker > .ui.input > input,
.daycy.time-range-picker > .ui.input > input,
.daycy.week-picker > .ui.input > input {
    background: unset !important;
    border: unset !important;
}

.daycy.date-range-picker > .ui.input.end-date > input,
.daycy.time-range-picker > .ui.input.end-time > input {
    text-align: right;
}

.daycy.date-range-picker > .date-range-seperator,
.daycy.time-range-picker > .time-range-seperator {
    flex: 0 0 auto;
    color: #888;
    margin-bottom: 0.3rem;
    margin-right: 0 !important;
}

.daycy.week-picker > label {
    color: rgba(34, 36, 38, 0.3);
    font-size: 80%;
    padding-right: calc(1em / 0.8);
}

/* Calendar */

.daycy.calendar {
    padding: 0;
}

.daycy.calendar > .title {
    display: flex;
    justify-content: space-between;
    font-size: 120%;
    font-weight: bold;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    padding: 0.883rem 1rem;
    color: rgb(34, 36, 38);
}

.daycy.calendar > .title > .icon {
    cursor: pointer;
    color: rgba(34, 36, 38, 0.3);
}

.daycy.calendar > .title > .icon:hover {
    color: rgba(34, 36, 38, 0.5);
}

.daycy.calendar > .grid > .row > .cell {
    display: inline-block;
    width: 2.3rem;
    height: 2.3rem;
    margin: 0.1rem;
    text-align: center;
    line-height: 2.3rem;
    color: rgb(34, 36, 38);
    border-color: rgba(34, 36, 38, 0.2);
    cursor: pointer;
    border-radius: 0.3rem;
}

.daycy.calendar > .grid > .row > .cell.range {
    background-color: #c4e2f9;
    border-color: rgba(34, 36, 38, 0.3);
}

.daycy.calendar > .grid > .row > .cell.selected {
    background-color: #2185d0;
    color: #FFF;
    font-weight: bold;
    border-color: rgba(34, 36, 38, 0.4);
}

.daycy.calendar > .grid > .row > .cell.week {
    font-size: 100%;
    color: #95ccf5;
    border-radius: unset;
    width: 3.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    margin: 0;
    /* border-right: 1px solid rgba(34, 36, 38, 0.15);*/
    border-right: 1px solid rgba(34, 36, 38, 0.075);
    cursor: unset;
}

.daycy.calendar > .grid > .row > .cell.week.selectable {
    cursor: pointer;
}

.daycy.calendar > .grid > .row > .cell.label {
    color: rgba(34, 36, 38, 0.5);
    font-size: 80%;
    height: 2.05rem;
    padding-top: 0.75rem;
    line-height: 1.3rem;
    cursor: unset;
}

.daycy.calendar > .grid > .row > .cell.week.label {
    height: 2.25rem;
    line-height: 1.5rem;
}

.daycy.calendar > .grid > .row > .cell.outside {
    opacity: 0.3;
}

.daycy.calendar > .grid > .row > .cell.hover {
    border-width: 1px;
    border-style: solid;
    line-height: calc(2.3rem - 2px);
}

.daycy.calendar > .grid > .row > .cell.first {
    margin-left: 0.85rem;
}

.daycy.calendar > .grid > .row > .cell:last-child {
    margin-right: 0.85rem;
}

.daycy.calendar > .grid > .row:last-child > .cell {
    margin-bottom: 0.6rem;
}

.daycy.calendar > .grid > .row:last-child > .cell.week {
    height: 3rem;
    margin-bottom: 0;
}

/* Clock */
.daycy.clock {
    padding: calc(1.5rem - 2px) !important;
}

.daycy.clock > .face {
    position: relative;
    width: calc(12rem + 4px);
    height: calc(12rem + 4px);
    border: 4px solid #F0F0F0;
    border-radius: 9999px;
}

.daycy.clock > .face > .button {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;

    cursor: pointer;

    background-color: #F0F0F0;
    border-radius: 9999px;

    color: rgba(0, 0, 0, 0.5);
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.5rem;

    transform: translate(-2px, -2px) translate(-50%, -50%);

    transition:
        transform 300ms ease,
        opacity 300ms ease,
        color 300ms ease,
        background-color 300ms ease;
}

.daycy.clock > .face > .button:hover {
    background-color: #E0E0E0;
}

.daycy.clock > .face > .button.selected {
    background-color: #2185d0;
    color: #FFF;
}

.daycy.clock > .face > .button.disabled {
    opacity: 0;
    pointer-events: none;
}

.daycy.clock > .face > .button.small {
    transform: scale(0.75) translate(-2px, -2px) translate(-50%, -50%);
    background-color: transparent;
}

.daycy.clock > .face > .button.small:hover {
    background-color: #F0F0F0;
}

.daycy.clock > .face > .button.small.selected {
    background-color: #2185d0;
}

.daycy.clock > .face > .pointer {
    position: absolute;
    transition: transform 300ms ease, opacity 900ms ease;
}

.daycy.clock > .face > .pointer.hide {
    opacity: 0;
}

.daycy.clock > .face > .pointer.hour {
    background-color: #2185d0;
}

.daycy.clock > .face > .pointer.minute {
    background-color: #A0A0A0;
}

.daycy.clock > .face > .period {
    border: none;
    color: rgba(0, 0, 0, 0.5);
    background-color: transparent;
    width: 2rem;
    height: 1rem;
    border-radius: 0.25rem;
    font-size: 0.6rem;
    line-height: 1rem;
    cursor: pointer;

    position: absolute;
    top: calc(8rem - 2px);

    transition: color 300ms ease;
}

.daycy.clock > .face > .period:hover {
    color: rgba(0, 0, 0, 0.75);
}

.daycy.clock > .face > .period.selected {
    color: #FFF;
}

.daycy.clock > .face > .period-bg {
    background-color: #2185d0;
    width: 2rem;
    height: 1rem;
    border-radius: 0.25rem;

    position: absolute;
    top: calc(8rem - 2px);

    transition: left 300ms ease;
}

.daycy.clock > .face > .period-bg.am,
.daycy.clock > .face > .period.am {
    left: calc(4rem - 2px);
}

.daycy.clock > .face > .period-bg.pm,
.daycy.clock > .face > .period.pm {
    left: calc(6rem - 2px);
}
